import { Text } from 'src/ui/components/atoms/Text'
import { UseFormReturn, useWatch } from 'react-hook-form'
import styles from './CouponsFormContent.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CouponFormType, CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import {
  isEmpty,
  isDefined,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useState,
  useEffect,
  useRef,
} from 'react'
import { FormSelectField } from 'src/ui/views/_components/FormSelectField'
import {
  CouponValidationMessage,
  TextFieldWithLoader,
} from 'src/ui/views/_components/TextFieldWithLoader'
import { useDebouncedCallback } from 'use-debounce'
import {
  CouponValidationType,
  transformTextToValidCoupon,
  CouponValidationResult,
} from 'src/core/Hotel/domain/CouponValidation.model'
import { useCoupons } from 'src/ui/contexts/CouponsContext'

interface Props {
  methods: UseFormReturn<CouponsFormValues>
  onValidateCoupon: (coupon: string) => Promise<CouponValidationResult>
  revalidate?: boolean
  keyForValidPromotionalCodeMessage: string
  keyForInvalidValidPromotionalCodeMessage: string
}

export const CouponsFormContent: FC<Props> = ({
  methods,
  onValidateCoupon,
  revalidate = false,
  keyForValidPromotionalCodeMessage,
  keyForInvalidValidPromotionalCodeMessage,
}) => {
  const { trans } = useTrans(['new-reservation'])
  const textFieldValue = useWatch({ name: 'value' })
  const inputField = useRef<HTMLInputElement>()
  const [showInputLoader, setShowInputLoader] = useState(false)
  const [validationMessage, setValidationMessage] = useState<
    CouponValidationMessage | undefined
  >(undefined)
  const {
    couponValidationResult,
    hideCouponNotification,
    setCouponValidationResult,
  } = useCoupons()

  useEffect(() => {
    const couponName = methods.getValues('value')
    const couponType = methods.getValues('type')

    if (
      revalidate &&
      couponType === CouponFormType.PromotionalCode &&
      !isEmpty(couponName)
    ) {
      validateCoupon(couponName)
      return
    }

    clearValidationMessage()
  }, [revalidate])

  const validateCoupon = async (coupon: string) => {
    const MIN_COUPONS_LENGTH = 6

    if (coupon.length < MIN_COUPONS_LENGTH) {
      setValidationMessage(undefined)
      return
    }

    if (isEmpty(coupon)) {
      clearValidationMessage()
      return
    }

    const couponType = methods.getValues('type')
    if (couponType !== CouponFormType.PromotionalCode) {
      return
    }

    setShowInputLoader(true)

    hideCouponNotification()
    const { type, validity }: CouponValidationResult =
      await onValidateCoupon(coupon)
    if (type !== couponValidationResult?.type) {
      setCouponValidationResult({ type, validity })
    }

    setValidationMessage(
      type === CouponValidationType.VALID
        ? {
            valid: trans(keyForValidPromotionalCodeMessage, {
              coupon,
            }),
          }
        : {
            invalid: trans(keyForInvalidValidPromotionalCodeMessage, {
              coupon,
            }),
          },
    )
    setShowInputLoader(false)
  }
  const validateCouponDebounced = useDebouncedCallback(validateCoupon, 1000)

  const promotionalCodeLabel = trans(
    'new-reservation:coupons-form_pick-coupon-select_values_promotional-code',
  )
  const groupCodeLabel = trans(
    'new-reservation:coupons-form_pick-coupon-select_values_group-code',
  )

  const options = [
    {
      label: promotionalCodeLabel,
      value: CouponFormType.PromotionalCode,
    },
    {
      label: groupCodeLabel,
      value: CouponFormType.GroupCode,
    },
  ]

  const isGroupCode = methods.getValues('type') === CouponFormType.GroupCode

  const formRegister = methods.register('type')

  const setTrimmedValue = (event: ChangeEvent<HTMLInputElement>) => {
    methods.setValue('value', event.target.value.trim())
  }

  const setValue = (event: ChangeEvent<HTMLInputElement>) => {
    const currentPosition = inputField.current?.selectionStart
    const { validCoupon, totalRemoveChars } = transformTextToValidCoupon(
      event.target.value,
    )

    methods.setValue('value', validCoupon)

    if (isDefined(currentPosition) && !isNull(currentPosition)) {
      // Evita que el cursor se vaya al final del campo de texto al haber
      // modificado su valor pasándolo a mayúsculas y eliminando caracteres
      // no validos
      inputField.current?.setSelectionRange(
        currentPosition - totalRemoveChars,
        currentPosition - totalRemoveChars,
      )
    }

    validateCouponDebounced(validCoupon)
  }

  const handleChange: ChangeEventHandler<HTMLSelectElement> = event => {
    formRegister.onChange(event)
    methods.setValue('value', '')

    if (event.target.value !== CouponFormType.PromotionalCode) {
      clearValidationMessage()
    }
  }

  const clearValidationMessage = () => {
    setValidationMessage(undefined)
  }

  const clearValue = () => {
    methods.setValue('value', '')
    clearValidationMessage()
  }

  const { ref, ...otherMethods } = methods.register('value')

  return (
    <>
      <label htmlFor="type">
        <Text fontStyle="s-500" color="dark" className="mb-xs" as="p">
          {trans('new-reservation:coupons-form_pick-coupon-select_label')}
        </Text>
      </label>
      <FormSelectField
        {...formRegister}
        onChange={handleChange}
        options={options}
        ariaLabel={trans(
          'new-reservation:coupons-form_pick-coupon-select_label',
        )}
      />
      <TextFieldWithLoader
        ref={e => {
          ref(e)
          //@ts-ignore
          inputField.current = e
        }}
        {...otherMethods}
        value={isDefined(textFieldValue) ? textFieldValue : ''}
        onClearInput={clearValue}
        autoFocus
        name="value"
        data-testid="coupon-input"
        onBlur={setTrimmedValue}
        onChange={setValue}
        showLoader={showInputLoader}
        validationMessage={validationMessage}
        maxLength={25}
        className={styles.couponWrapper}
        ariaLabel={trans(
          'new-reservation:coupons-form_pick-coupon-input_aria-label',
        )}
      />
      {!isGroupCode && (
        <div className="mt-m">
          <Text fontStyle="s-300" color="dark">
            {trans('new-reservation:coupons-form_pick-coupon-group-code_info')}
          </Text>
        </div>
      )}
    </>
  )
}
