import { FC } from 'react'

interface Props {
  color: string
}

export const CouponIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M14.3335 10.7852L12.2748 8.72649C12.0564 8.47032 11.7367 8.32275 11.4001 8.32275C11.0635 8.32275 10.7438 8.47032 10.5254 8.72649C9.56628 9.68562 11.7699 13.3488 10.8081 14.3106C10.5792 14.5483 10.2634 14.6826 9.9334 14.6826C9.6034 14.6826 9.28761 14.5483 9.05871 14.3106L7.00001 12.2519"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8668 9.31833L13.9664 8.21875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.36623 14.819L8.4667 13.7185"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="16.5504"
      cy="5.17778"
      rx="1.28447"
      ry="1.28447"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2943 1C10.9149 0.99984 10.5524 1.15651 10.2925 1.4329L1.37145 10.9184C1.11676 11.1888 0.983237 11.5513 1.00169 11.9224C1.02014 12.2934 1.18896 12.6409 1.46923 12.8847L10.3858 20.6617C10.9442 21.1476 11.7867 21.1052 12.2934 20.5657L20.6305 11.6615C20.8679 11.4084 21.0001 11.0744 21.0003 10.7273V2.36892C20.9998 2.00523 20.8548 1.65664 20.5972 1.3999C20.3397 1.14315 19.9906 0.999295 19.6269 1H11.2943Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default CouponIcon
