import {
  checkCouponValidation,
  CouponValidationResult,
} from 'src/core/Hotel/domain/CouponValidation.model'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'

export const getCouponValidation =
  ({ hotelRepository }: Dependencies) =>
  async (
    couponId: string,
    hotelId: string,
    checkIn: string,
    checkOut: string,
  ): Promise<CouponValidationResult> => {
    const coupon = await hotelRepository.getCoupon(couponId, hotelId)

    return checkCouponValidation(coupon, checkIn, checkOut)
  }

interface Dependencies {
  hotelRepository: HotelRepository
}
