import { FC, useRef, useState } from 'react'
import {
  FloatingArrow,
  arrow,
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  Placement,
} from '@floating-ui/react'

import styles from './Tooltip.module.scss'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  triggerElement: string | JSX.Element
  tooltipContent: string
  placement?: Placement
  arrowOffset?: number
}

export const Tooltip: FC<Props> = ({
  triggerElement,
  tooltipContent,
  placement = 'top',
  arrowOffset,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const ARROW_HEIGHT = 12
  const ARROW_WIDTH = 14
  const GAP = 5

  const { trans } = useTrans(['new-reservation'])

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    // Se asegura de que el tooltip siempre esté en pantalla
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distancia entre el baseline del trigger y el inicio del tooltip
      offset(ARROW_HEIGHT + GAP),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  // Mergea las interactions en prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()}>
        {triggerElement}
      </button>
      <FloatingPortal>
        {isOpen && (
          <div
            className={styles.tooltip}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <Flex gap="xxs">
              <label>{tooltipContent}</label>
              <button
                onClick={() => setIsOpen(false)}
                className={styles.closeButton}
              >
                <Icon
                  size="m"
                  color="text-light"
                  icon={CloseIcon}
                  ariaLabel={trans('tooltip_close')}
                />
              </button>
            </Flex>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              height={ARROW_HEIGHT}
              width={ARROW_WIDTH}
              fill="var(--b-color-tooltip-background)"
              staticOffset={arrowOffset}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  )
}
